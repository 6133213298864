require('./bootstrap');


matchHeights = ($className) => {

  var getDivs = document.getElementsByClassName($className);

  var arrayLength = getDivs.length;
  var heights = [];

  for (var i = 0; i < arrayLength; i++) {
    heights.push(getDivs[i].offsetHeight);
  }

  function getHighest() {
    return Math.max(...heights);
  }

  var tallest = getHighest();

  for (var i = 0; i < getDivs.length; i++) {
    getDivs[i].style.height = tallest + "px";
  }
}